import React from 'react';
import './App.css';
import { Row, Col, Modal, Space, Typography, Card, Radio,Input, InputNumber, Button, Switch, notification } from 'antd';
import logo from './logo.png'
import i1 from './2.png'
import i2 from './3.png'
import i3 from './5.png'
import i4 from './4.png'
import { UserOutlined } from '@ant-design/icons';

import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database"
const firebaseConfig = {
  apiKey: "AIzaSyBIK63K3RkQuqf1pitC1N6UNWmgNbo0fSk",
  authDomain: "viziere-tg.firebaseapp.com",
  databaseURL: "https://viziere-tg.firebaseio.com",
  projectId: "viziere-tg",
  storageBucket: "viziere-tg.appspot.com",
  messagingSenderId: "512726543576",
  appId: "1:512726543576:web:d482018b5a06b83bdefbad",
  measurementId: "G-30W6GEVCYF"

};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.analytics().logEvent('notification_received');


const img4 = 'https://firebasestorage.googleapis.com/v0/b/viziere-tg.appspot.com/o/4.png?alt=media&token=300d352c-18f3-4352-a8b8-9140d1861c84'
const img5 = 'https://firebasestorage.googleapis.com/v0/b/viziere-tg.appspot.com/o/5.png?alt=media&token=e1068539-69b6-410c-962e-99772e0f5ddb'

const screen = window.screen
const { Text, Title } = Typography;
var database = firebase.database();


class App extends React.Component {

   state = {
    value: 1,
    name: '',
    viziere: 1,
    genti: 0,
    school: false,
    pers: false,
    delivery: false,
    total: 30,
    visible: false,
  };



  openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        OK
      </Button>
    );
    notification.open({
      message: 'Cookies',
      description:
        'Acest website folosește cookies. Utilizarea site-lui este posibilă doar cu aceștia și confirmă acceptarea de cookies.',
      btn,
      key,
    });
  };


  componentDidMount() {
    this.openNotification();
    firebase.auth().signInAnonymously().catch(function(error) {

    });
    
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
    window.location.reload()
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
    window.location.reload()
  };
  onChange = e => {
    this.setState({
      value: e.target.value,
    });
  };


  onChangeNR = (value) => {
    this.setState({
      viziere: value,
    })
  }

  onChangeGenti= (value) => {
    this.setState({
      genti: value,
    })
  }

  

  onChangeSch = (checked) => {
    const Del = document.getElementById("school")
    if(checked){
      Del.style.display = 'block'
    }else{
      Del.style.display = 'none'
    }
    this.setState({
      school: checked,
    });

  }
  onChangePers = (checked) => {
    this.setState({
      pers: checked,
    })

  }

  onCommand =() => {

    var all_ok = true;
    const name = document.getElementById('nume').value
    const email = document.getElementById('email').value
    const phone = document.getElementById('nr').value
    const schoolName = document.getElementById('schoolName').value
    
    if(name.length < 3) {
      alert('Numele nu este valid.')
      all_ok = false;
      return;
    }
    if(email.length < 10 || !email.includes('@') || !email.includes('.')) {
      alert('Email-ul nu este valid.')
      all_ok = false;
      return;
    }
    if(phone.length < 9 ){
      alert('Numarul de telefon nu este valid.')
      all_ok = false;
      return;
    }
    
        if(this.state.school){
            if(schoolName.length < 5){
              all_ok = false;
              alert('Introduceți denumirea completă a școlii');
              return;
            }
        }
    
    
    if(all_ok){
      this.totalCalculator();

      document.getElementById('comanda').style.display = 'block'
      document.getElementById('calc').style.display = 'none'
    }

  }

  onCancel =()=> {
    document.getElementById('comanda').style.display = 'none'
    document.getElementById('calc').style.display = 'block'
  }

  totalCalculator = () => {
    var ppu = 30;
    if (this.state.value === 2) {
      ppu = 70
    }
    if  (this.state.pers) {
      ppu=ppu+5
    }
    if (this.state.school) {
      ppu=ppu+10
    }
    
    var total = ppu * this.state.viziere;
    total = total + (this.state.genti * 40)

    
    this.setState({
      total: total
    })
  }


  onValidationSuccess = () => {
  
    this.showModal();
    this.totalCalculator();
    const name = document.getElementById('nume').value
    const email = document.getElementById('email').value
    const phone = document.getElementById('nr').value
    const schoolName = document.getElementById('schoolName').value
    
      
        database.ref(Date.now()).set({
          numele: name,
          email: email,
          telefon: phone,
          viziere: this.state.viziere,
          tip_vizier: this.state.value,
          scoala: schoolName,
          sticher_personalizat: this.state.pers,
          timestamp: Date.now(),
          genti: this.state.genti,
          suma_totala: this.state.total
        });
      
  }

render() {
  return (
    <div >
      
      <Row>
        <Col span={6}>
          <img src={logo} alt='total gravura logo' className='logo'></img>
        </Col>
        <Col span={17} >
        <br/>
          <Title className='titles'>Comandă acum,</Title>
          <Title className='titles'>Achită cînd îl primești.</Title>
        </Col>
      </Row>
            <br/>


      <div className='inlineM'>
        <div className='images' style={{margin: 'auto 5vw'}}>
          <Title level={2}>STOP COVID-19</Title>
          <Title level={3}>Viziere protectoare pentru copii</Title>
          <Text style={{fontSize: '1.2em'}}>
                  Viziera de protectie pentru față nu are bravuri, margini tăioase sau alte 
                  defecte care ar putea provoca disconfort sau răni în cursul utilizării. 
                  Viziera permite purtarea concomitenta a ochelarilor și măștilor.
                  Conform testelor și calculelor de productie, pentru o protecție 
                  mai bună este recomandat purtarea și cu masca de protecție. 
                  Viziera este o alternativă la ceea ce există pe piață și nu are
                  analog după comoditate,siguranță și calitatea 
                  materialelor utilizate la producere.
          </Text>
          <br/>
          <br/>
          <Title level={4}>Pentru reducerea zgârieturilor și menținerea
              transparenței și clarității vizierei, ca într-un final să nu fie afectata vederea copilului,
               vă propunem procurarea vizierelor în set cu husă.
          </Title>
<br/>

          
        </div>
        <div>
        <img alt="ecran de protectie" src={i1} className='images'></img>
        </div>
      </div>

      <div style={{display: 'inline-flex'}}>
          <img alt='viziere pentru copii' src={img5} style={{width: '25vw', height: '25vw', margin: '30px 4vw' }}></img>
          <img alt='protectie impotriva covid si coronavirus' src={i2} style={{width: '25vw', height: '25vw', margin: '30px 4vw' }}></img>
          <img alt='vizier pentru copii' src={i4} style={{width: '25vw', height: '25vw', margin: '30px 4vw' }}></img>
      </div>

      <div className='inlineM'>
        <div className='images' style={{margin: 'auto 5vw'}}>
          <Title level={2}>Viziere pentru adulți</Title>
          <Title level={3}>Ecrane de protecție</Title>
          <Text style={{fontSize: '1.2em'}}>
          Viziera de protecție transparentă din PET-G transparent, pentru întreaga față.
          <ul>
            <li>Oferă protecție împotriva particulelor mici solide</li>
            <li>Potrivită pentru protecția ochilor împotriva aschiilor solide</li>
            <li>Protejează împotriva stropirii cu lichid</li>
            <li>Sistemul de prindere prin arici permite fixarea ușoară pe cap și adaptarea după circumferința capului.</li>
          </ul>          
          </Text>
          <br/>
        </div>
        <div>
        <img alt='vizier pentru maturi' src={i3} className='images'></img>
        </div>
      </div>
  <div style={{textAlign: 'center', margin: '0px auto'}}>
    <Title level={2}>Cum se asamblează vizierul pentru copii:</Title>
    {screen.width > 600 && <iframe title='vizier pentru copii ansamblat' width="560" alt='vizier ansamblat' height="315" src="https://www.youtube.com/embed/5PNrp-PAapw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>}
    {screen.width < 600 && <iframe title='cum sa faci un vizier' width="336" height="190" src="https://www.youtube.com/embed/5PNrp-PAapw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>}
  
  </div>
      
<div className='spacer' />

<Title align='center' level={2}>Comandă acum!</Title>

     <Row >
   <Col span={3}></Col>
        <Col span={18} >
            

          <Space direction='vertical' style={{width: '100%'}}>
          <br/>
            <Input id='nume' size="large" placeholder="Numele și Prenumele cumpărătorului" prefix={<UserOutlined onChange={this.onChangeName}/>} />
            <Input id='email' size="large" placeholder="Email"  />
            <Input id='nr' size="large" placeholder="Telefon" />
            <br/>

          <Radio.Group size='large' onChange={this.onChange} value={this.state.value}>
            <Radio value={1}>Pentru copii</Radio>
            <Radio value={2}>Pentru adulți</Radio>
          </Radio.Group>
        <br/>
            {this.state.value < 2 && <Text strong>Numărul de viziere. (30 Lei)</Text>}
            {this.state.value > 1 && <Text strong>Numărul de viziere. (70 Lei)</Text>}
            <InputNumber size="large" min={1} max={100000} defaultValue={1} onChange={this.onChangeNR} />
            <br/>


{/* KIDS */}
          
            <Text strong>Numărul de genți pentru viziere. (40 Lei)</Text>
            <InputNumber size="large" min={0} max={100000} defaultValue={0} onChange={this.onChangeGenti} />
            <br/>
            <Space>
                <Switch  onChange={this.onChangePers} />
                <Text strong>Sticher pentru nume (5 Lei)</Text>
            </Space>
            
            <Space>
                <Switch  onChange={this.onChangeSch} />
                <Text strong>Logotipul școlii (10 Lei)</Text>
            </Space>
            
            <Space direction='vertical' id='school' style={{display: "none"}}>
                <Input id='schoolName' size="large" placeholder="Numele școlii"  />
            </Space>


            
            <br/>

              <Space>
                <Button size='large' type='primary' id='calc' onClick={this.onCommand}>Calculează</Button>
              </Space>
              <Card title="Comanda dumneavoastră" style={{ width: '80%', display: "none"}} id='comanda'>
              {this.state.value === 1 && <p>{this.state.viziere} vizier/e copii x 30 Lei</p>}
              {this.state.genti > 0 && <p>{this.state.genti} genti vizier x 40 Lei</p>}
              {this.state.value === 2 && <p>{this.state.viziere} vizier/e adulți x 70 Lei</p>}
                {this.state.school && <p>{this.state.viziere} logotip școală x 10 Lei</p>}
                {this.state.pers && <p>{this.state.viziere} sticker/e pentru nume x 10 Lei</p>}
                Total: {this.state.total}
                <br/>

                <Space>
                <Button size='large' type='danger' onClick={this.onCancel}>Anulează</Button>
                <Button size='large' type='primary'  onClick={() => this.onValidationSuccess()}>Confirm</Button>
              </Space>
              </Card>

            
          </Space>
        </Col>
      <div className='spacer'></div>
      <img alt='viziere Chisinau' src={img4} className='imagebottom'></img>
      
      </Row>

      

        <Modal
          title="Mulțumim pentru comandă"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width='90%'
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              OK
            </Button>,
          ]}
        >
          <h3>Comanda este confirmată!</h3>
          {this.state.value === 1 && <p>{this.state.viziere} vizier/e copii x 30 Lei</p>}
              {this.state.genti > 0 && <p>{this.state.genti} genti vizier x 40 Lei</p>}
              {this.state.value === 2 && <p>{this.state.viziere} vizier/e adulți x 70 Lei</p>}
                {this.state.school && <p>{this.state.viziere} logotip școală x 10 Lei</p>}
                {this.state.pers && <p>{this.state.viziere} sticker/e pentru nume x 10 Lei</p>}
               <h3> Total: {this.state.total}</h3>
          <h2>Comanda va fi gata în 2 zile lucrătoare.</h2>
 <h2>Vă rugăm să o colectați de la Cernăuți 20a, Chișinău</h2>
          <h3>Pentru detalii apelați: 068085550</h3>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2721.8603632096024!2d28.870875151240675!3d46.984077238663424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97945a8185555%3A0x7f7dd68361666fb!2zU3RyYWRhIENlcm7Eg3XIm2kgMjBhLCBDaGnImWluxIN1IDIwMzIsIE1vbGRvdmE!5e0!3m2!1sro!2s!4v1597999590611!5m2!1sro!2s" 
                width="80%" 
                title='viziere chisinau'
                height="450" 
                frameborder="0" 
                style={{border:0, margin: '10px 10%'}} 
                allowfullscreen="" 
                aria-hidden="false"
                tabindex="0">
          </iframe>

        </Modal>
      <div style={{width: '95%', textAlign: 'right', }} >
      <h3><a href='tel:+37368085550'>Pentru detalii apelați: 068085550</a></h3>
      <div class='spacer'></div>

      </div>

    </div>
  );
  }
}

export default App;
